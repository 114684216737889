import styled from "styled-components";
import svg from "../../assets/images/dec.png";

import magno from "../../assets/images/magno_history.jpeg";
import { useNavigate } from "react-router-dom";

export default function AboutUs(){
    const navigate = useNavigate();

    function GoToInstitutionalPage(){
        navigate("/institucional")
    }

    return(
        <Container>
            <Informations>
                <Line />
                <Title>
                    Sobre Nós
                </Title>
                <History>
                    Grupo Magno
                    <br/>
                    Divisão especializada em soluções
                    <br/>
                    <br/>
                        Nós, da Magno Especializada, somos uma empresa dedicada a fornecer soluções 
                        completas e eficientes de engenharia para as necessidades de ar condicionado, 
                        ventilação, gás encanado e aquecimento de água.
                    <br/>
                    <p>
                        Com anos de experiência e um compromisso inabalável com a excelência, estamos 
                        aqui para atender às demandas dos nossos clientes em todos os aspectos...
                    </p>
                </History>
                <ContainerButtonSvg>
                    <img src={svg} alt="detalhe em pontinhos"/> 
                    <ButtonKNowMore onClick={GoToInstitutionalPage}>Ver mais</ButtonKNowMore>
                </ContainerButtonSvg>
            </Informations>
            <Magnoícture>
                <Picture src={magno} alt="Família fundadora da Magno" />
                <PictureFrame />
            </Magnoícture>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 35px 50px 10px;
    @media (max-width:1150px) {
        padding: 35px 30px 10px;
    }
    @media (max-width:1090px) {
        padding: 35px 10px 10px;
    }
    @media (max-width:1000px) {
        box-shadow: 0px 0px 10px 0px #000000;
        padding: 25px 20px 20px;
    }
`
const Informations = styled.div`
    width: 50%;
    @media (max-width:1000px) {
        width: 100%;
    }
`
const Line = styled.div`
    width: 125px;
    height: 1px;
    background-color: #A0252E;
    @media (max-width:800px) {
        width: 80px;
    }
`
const Title = styled.div`
    color: #000000;
    font-weight: 700;
    font-size: 38px;
    box-sizing: border-box;
    padding-top: 15px;
`
const History = styled.div`
    margin-top: 35px;
    margin-bottom:45px;
    font-size: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: #000000;
    text-align: justify;
    @media (max-width:800px) {
        line-height: 25px;
    }
    @media (max-width:500px) {
        p{
            display: none;
        }
    }
`
const ContainerButtonSvg = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        width: 30px;
        position: absolute;
        left: 0;
        top: 0
    }
    @media (max-width:800px) {
        img{
            width: 25px;
        }
    }
    @media (max-width:800px) {
        img{
            display: none;
        }
    }
`
const ButtonKNowMore = styled.button`
    width: 156px;
    height:40px;
    background: linear-gradient(180deg, #FB7D6C 0%, #560404 100%);
    box-shadow: 5px 8px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    @media (max-width:400px) {
        width: 85%;
        font-size: 14px;
    }
`
const Magnoícture = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width:1000px) {
        display: none;
    }
`
const PictureFrame = styled.div`
    width: 60%;
    height: 480px;
    background-color: #A0252E;
    border-radius: 0px 30px 30px 0px;
    position: relative;
`
const Picture = styled.img`
    box-shadow: 8px 8px 5px 6px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
    width: 400px;
    height: auto;
    position: absolute;
    right: 150px;
    z-index: 2;
    @media (max-width:1150px) {
        position: absolute;
        right: 120px;
    }
    @media (max-width:1090px) {
        position: absolute;
        right: 90px;
    }
`