import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react"

//css swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import { register } from 'swiper/element/bundle';
register();

export default function CarouselServices({arrayProducts}){
    return(
        <Container>
            <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}

            >
                {arrayProducts.length > 0?
                arrayProducts.map((p)=>(
                    <SwiperSlide key={p.id}><img alt="slide" src={p} className="slide" /></SwiperSlide>
                ))
                :
                ""}
            </Swiper>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`