import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import Contact from "../../components/contactServicesPage/Contact";
import water from "../../assets/images/WATER1.png";
import { useEffect } from "react";
import ServicesComponent from "../../components/servicesComponent/ServicesComponent";

import water2 from "../../assets/images/water2.png";
import water3 from "../../assets/images/water4.jpeg";
import water80 from "../../assets/images/water80.jpeg";
import water85 from "../../assets/images/water85.jpeg";
import water86 from "../../assets/images/water86.jpeg";

export default function WaterPage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const title = "Aquecimento de água";

    const sistemsArray = [
        {
            id: 1,
            name: "AQUECIMENTO A GÁS",
            text: "Os sistemas de água quente são essenciais para garantir conforto e praticidade em residências e estabelecimentos comerciais. Com diferentes tecnologias disponíveis, esses sistemas atendem às necessidades de aquecimento de água para diversas aplicações. Os aquecedores de água a gás oferecem uma solução eficaz e econômica para garantir água quente instantânea. Com design compacto e operação simples, esses sistemas proporcionam conforto térmico de forma ágil e eficiente.",
            points: ["Água quente instantânea", "Economia de energia", "Design compacto"],
            icones: ["Conforto Rápido", "Economia Garantida", "Design Prático", "Operação Simplificada"],
            image: [water3, water80, water2, water86 ]
        },
        {
            id: 2,
            name: "AQUECIMENTO SOLAR",
            text: "Os sistemas de água quente são essenciais para garantir conforto e praticidade em residências e estabelecimentos comerciais. Os sistemas de aquecimento solar utilizam a energia do sol para aquecer a água, oferecendo uma opção sustentável e economicamente vantajosa. Com painéis solares eficientes, esses sistemas contribuem para a redução do consumo de energia convencional.",
            points: ["Sustentabilidade ambiental", "Economia a longo prazo", "Baixa emissão de carbono"],
            icones: ["Energia Renovável", "Economia Sustentável", "Baixa Emissão de Carbono", "Tecnologia Solar Avançada"],
            image: [water3, water80, water2, water85]
        }
    ];

    return(
        <>
            <Navbar />
            <MainWater>
                < Banner>
                    <img src={water} alt="banner com ar água quente" />
                </Banner>
                <ServicesComponent sistemsArray={sistemsArray} title={title} />
                <Contact />
            </MainWater>
            <Footer />
        </>
    )
}

const MainWater = styled.div`
    width: 100vw;
    min-height: 700px;
    margin-top: 80px;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`