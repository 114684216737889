import styled from "styled-components"
import Navbar from "../components/Navbar"
import Carousel from "../components/carousel/Carousel"
import Footer from "../components/Footer"
import AboutUs from "../components/mainHomePage/AboutUs"
import ServicesAndSolutions from "../components/mainHomePage/ServicesAndSolutions"
import ServicesPerformed from "../components/mainHomePage/ServicesPerformed"
import { useEffect } from "react"

export default function HomePage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return(
        <>
            <Navbar />
            <Carousel />
            <MainFirstPage>
                <AboutUs />
            </MainFirstPage>
            <ServicesAndSolutions />
            <ServicesPerformed />
            <Footer />
        </>
    )
}

const MainFirstPage = styled.main`
    min-height: 600px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 100px 50px 50px;
    @media (max-width:1000px) {
        padding: 80px 30px 40px;
    }

`