import GlobalStyle from "./assets/style/GlobalStyle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import InstitutionalPage from "./pages/InstitutionalPage";
import AirPage from "./pages/servicesPages/AirPage";
import GasPage from "./pages/servicesPages/GasPage";
import VentilationPage from "./pages/servicesPages/VentilationPage";
import WaterPage from "./pages/servicesPages/WaterPage";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/contato" element={<ContactPage/>} />
        <Route path="/institucional" element={<InstitutionalPage />} />
        <Route path="/servicos/ar-condicionado" element={<AirPage/>} />
        <Route path="/servicos/instalação-gas" element={<GasPage/>} />
        <Route path="/servicos/ventilacao" element={<VentilationPage/>} />
        <Route path="/servicos/aquecimento-agua" element={<WaterPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
