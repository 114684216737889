import { useState } from "react"
import styled from "styled-components"

import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

export default function FormContact() {
    const [open, setOpen] = useState(false);
    const [unidade, setUnidade] = useState(null);
    const [name, setName] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [opinion, setOpinion] = useState();

    const units = ["João Pessoa - PB", "Cabedelo - PB"];

    function dataForm(e) {
        e.preventDefault();

        if (!unidade) {
            alert("Selecione uma unidade, por favor.");
            return;
        }

        let messageWpp = `Olá! Me chamo ${name}, Sou de ${city} - ${state}. \n ${opinion}.`
        let msgCod = encodeURIComponent(messageWpp);
        if (unidade === "João Pessoa - PB") {
            let linkWpp = `https://wa.me/5583987985654?text=${msgCod}`
            window.open(linkWpp);
            setUnidade(null);
            setName("");
            setState("");
            setCity("");
            setOpinion("");

        } else {
            let linkWpp = `https://wa.me/5583986098536?text=${msgCod}`
            window.open(linkWpp);
            setUnidade(null);
            setName("");
            setState("");
            setCity("");
            setOpinion("");
        }
    }

    function openOptions(){
        setOpen(!open);
    }

    return (
        <BoxForm>
            < Line />
            <Title>ENVIE-NOS UMA MENSAGEM</Title>
            <Form onSubmit={dataForm} >
                <BoxInput >
                    <SelectState size="40px" onClick={openOptions}>
                        <p>{unidade || "SELECIONE A UNIDADE"}</p>
                        {open ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                        <ContainerUnits open={open}>
                            {units.length > 0 ?
                                units.map((unit, idx)=>(
                                    <Unit key={idx} onClick={()=> setUnidade(unit)}>
                                        {unit}
                                    </Unit>
                                ))
                                :
                                ""}
                        </ContainerUnits>
                    </SelectState>
                </BoxInput>
                <BoxInput>
                    <InputForm 
                        type="text" 
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        placeholder="Nome" 
                        required
                        size="40px" />
                </BoxInput>
                <BoxInput>
                    <InputForm
                        type="text"
                        value={state}
                        onChange={e => setState(e.target.value)}
                        placeholder="Estado"
                        required
                        size="40px" />
                </BoxInput>
                <BoxInput>
                    <InputForm
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        placeholder="Cidade"
                        required
                        size="40px" />
                </BoxInput>
                <BoxInput>
                    <TextArea 
                        value={opinion} 
                        onChange={e => setOpinion(e.target.value)} 
                        placeholder="Digite sua mensagem..." 
                        required 
                        size="100px" />
                </BoxInput>
                <Button type="submit" >Enviar</Button>
            </Form>
        </BoxForm>
    )
}

const BoxForm = styled.div`
    width: 100%;
    min-height: 500px;
    box-sizing: border-box;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`
const Line = styled.div`
    width: 140px;
    height: 1px;
    background-color: #494747;
`
const Title = styled.h4`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #383636;
    margin-top: 20px;
    margin-bottom: 25px;
`
const Form = styled.form`
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const BoxInput = styled.div`
    width: 100%;
    min-height: 50px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Button = styled.button`

    background: #171717;
    border: 1px solid #EBEBEB;
    border-radius: 5px;

    width: 78px;
    height: 30px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    cursor: pointer;
    &:hover{
        background-color: #ccc;
        color: #171717;
    }
`
const SelectState = styled.div`
    width: 450px;
    height: ${(props) => props.size};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ccc;
    border-bottom: 1px solid #ACAAAA;
    box-sizing: border-box;
    padding: 12px 15px 0px 10px;
    cursor: pointer;
    position: relative;

    p{
        font-size: 16px;
        letter-spacing: 0.1em;
        color: #786F6F;
    }
    svg{
        font-size: 20px;
        letter-spacing: 0.1em;
        color: #786F6F;
    }

    @media (max-width: 615px) {
        width: 95%;
    }
`
const ContainerUnits = styled.div`
    position: absolute;
    left: 0;
    top: 40px;
    width: 450px;
    height: auto;
    display: ${(props) => props.open ? "flex" : "none"};
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: #ccc;
    @media (max-width: 615px) {
        width: 95%;
    }
`
const Unit = styled.div`
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 12px 0px 0px 12px;
    background-color: #ccc;
    border-bottom: 1px solid #ACAAAA;
    text-align: start;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #786F6F;
    cursor: pointer;
    &:hover{
        border-bottom: 1px solid #707070;
        background-color: #9b9999;
    }
`
const InputForm = styled.input`
    width: 450px;
    height: ${(props) => props.size};
    background-color: #ccc;
    border: none;
    border-bottom: 1px solid #ACAAAA;
    box-sizing: border-box;
    padding: 0px 0px 0px 10px;
    outline: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 60px;
    letter-spacing: 0.1em;
    color: #786F6F;
    &::placeholder{
        color: #786F6F;
        font-size: 16px;
        width:  ${(props) => props.placeholderW};
        height: ${(props) => props.placeholderH};
    }
    @media (max-width: 615px) {
        width: 95%;
    }
`
const TextArea = styled.textarea`
    width: 450px;
    height: ${(props) => props.size};
    background-color: #ccc;
    border: none;
    border-bottom: 1px solid #ACAAAA;
    box-sizing: border-box;
    padding: 0px 0px 0px 10px;
    outline: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: #786F6F;
    @media (max-width: 615px) {
        width: 95%;
    }
`