import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import contato from "../assets/images/contato.png";
import { Link } from "react-router-dom";
import FormContact from "../components/contactPage/FormContact";
import { useEffect } from "react";

export default function ContactPage() {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Navbar />
            <MainContactPage>
                <Banner>
                    <img src={contato} alt="" />
                </Banner>
                <BoxLinks>
                    <Line />
                    <Title>LINKS ÚTEIS</Title>
                    <Links>
                        <Util>
                            <p>
                                <a href="https://wa.me/5583987985654?text=Ol%C3%A1%2C+gostaria+de+fazer+um+or%C3%A7amento." target="_blank">
                                    Unidade de <br />João Pessoa
                                </a>
                            </p>
                        </Util>
                        <Util>
                            <p>
                                <a href="https://wa.me/5583986098536?text=Ol%C3%A1%2C+gostaria+de+fazer+um+or%C3%A7amento." target="_blank">
                                    Unidade de <br />Cabedelo
                                </a>
                            </p>
                        </Util>
                        <Util>
                            <p>
                                <a href="https://www.instagram.com/magno_especializada/" target="_blank">
                                    Instagram Magno Especializada
                                </a>
                            </p>
                        </Util>
                        <Util>
                            <p>
                                <a href="https://www.magnodistribuidora.com.br/" target="_blank">
                                    Site Magno Distribuidora
                                </a>
                            </p>
                        </Util>
                    </Links>
                </BoxLinks>
                <FormContact />
            </MainContactPage>
            <Footer />
        </>
    )
}

const MainContactPage = styled.main`
    width: 100vw;
    min-height: 500px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`
const BoxLinks = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 20px;
    gap: 6px;
`
const Line = styled.div`
    width: 140px;
    height: 1px;
    background-color: #494747;
`
const Title = styled.h4`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #383636;
    margin-top: 20px;
`
const Links = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    gap: 50px;
    margin-top: 35px;
    @media (max-width: 450px) {
        gap: 20px;
    }
`
const Util = styled.div`
    box-sizing: border-box;
    width: 180px;
    min-height: 80px;
    background: #FFFFFF;
    border: 1px solid #ccc;
    box-shadow: 5px 10px 10px #FFFFFF;
    border-radius: 15px;
    cursor: pointer;
   
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    P{
        display: block;
        width: 100%;
        height: 100%;
        a{
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 19px;
            line-height: 30px;
            text-decoration: none;
            color: #786F6F;
        }
    }
     &:hover{
        box-shadow: 9px 9px 12px -3px rgba(0,0,0,0.75);
        a{
            color: #A0252E;
        }
    }
    @media (max-width: 450px) {
        width: 80%;
        min-height: 70px;
        border-radius: 15px;
        a{
            font-size: 16px;
            line-height: 20px;
        }
    }
`