import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import Contact from "../../components/contactServicesPage/Contact";
import gas from "../../assets/images/GAS.png";
import { useEffect } from "react";
import ServicesComponent from "../../components/servicesComponent/ServicesComponent";


import gas1 from "../../assets/images/gas.jpg";
import gas2 from "../../assets/images/gas2.jpg";
import gas3 from "../../assets/images/gas3.jpeg";
import cityGas from "../../assets/images/cityGas.jpeg";

export default function GasPage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const title = "Gás";

    const sistemsArray = [
        {
            id: 1,
            name: "GN",
            text: "O Gás Natural (GN) é uma fonte de energia amplamente utilizada para aquecimento de água em residências e estabelecimentos comerciais. Sua disponibilidade contínua e eficiência energética o tornam uma escolha popular para sistemas de aquecimento a gás.",
            points: ["Fonte de energia contínua", "Eficiência energética", "Baixas emissões de poluentes"],
            icones: ["Fonte Contínua", "Eficiência Energética", "Baixas Emissões", "Versatilidade"],
            image: [gas3, cityGas]
        },
        {
            id: 2,
            name: "GLP",
            text: "O Gás Liquefeito de Petróleo (GLP) é uma alternativa versátil para aquecimento de água, amplamente utilizado em áreas onde o acesso ao gás natural é limitado. Sua portabilidade e fácil armazenamento o tornam uma escolha conveniente para diversos contextos.",
            points: ["Portabilidade e facilidade de armazenamento", "Disponibilidade em áreas remotas", "Opção para onde o gás natural não está disponível"],
            icones: ["Portabilidade", "Disponibilidade Remota", "Flexibilidade", "Conveniência"],
            image: [gas1, gas2, gas3]
        }
    ];

    return(
        <>
            <Navbar />
            <MainGas>
                <Banner>
                    <img src={gas} alt="sistema de gás" />
                </Banner>
                <ServicesComponent sistemsArray={sistemsArray} title={title} />
                <Contact/>
            </MainGas>
            <Footer />
        </>
    )
}

const MainGas = styled.div`
    width: 100vw;
    min-height: 700px;
    margin-top: 80px;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`