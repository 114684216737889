import styled from "styled-components";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import institucional from "../assets/images/banner2.png";
import magnoPicture from "../assets/images/logo2.png";
import { useEffect } from "react";

export default function InstitutionalPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            < MainInstitucionalPage>
                <Banner>
                    <img src={institucional} alt="" />
                </Banner>
                <BoxTitle>
                    <Line />
                    <Title>MAGNO ESPECIALIZADA</Title>
                </BoxTitle>
                <BoxInstitucional>
                    <BoxTitleHistory>
                        <FirstTitle>SOBRE NOSSOS</FirstTitle>
                        <SecondTitle>SERVIÇOS</SecondTitle>
                    </BoxTitleHistory>
                    <History>
                        <p> Nós, da Magno Especializada, somos uma empresa dedicada a fornecer soluções completas e eficientes 
                            de engenharia para as necessidades de ar condicionado, ventilação, gás encanado e aquecimento de água. 
                            Com anos de experiência e um compromisso inabalável com a excelência, estamos aqui para atender às 
                            demandas dos nossos clientes em todos os aspectos. </p>
                        <p>
                            Nosso portfólio abrange uma ampla gama de equipamentos de alta qualidade, projetados para oferecer 
                            conforto térmico e bem-estar em residências, estabelecimentos comerciais e industriais. Desde sistemas 
                            de ar condicionado até soluções de ventilação e aquecimento, trabalhamos com as principais marcas e 
                            tecnologias do mercado para garantir o máximo desempenho e eficiência em cada projeto.
                        </p>
                        <p>
                            Além disso, nossa expertise se estende além da simples venda de equipamentos. Nossa equipe altamente 
                            qualificada e experiente também realiza instalações precisas e confiáveis, garantindo que cada sistema 
                            seja implementado com perfeição. Desde pequenas residências até grandes empreendimentos, estamos 
                            preparados para lidar com projetos de qualquer escala, fornecendo soluções sob medida que atendam às 
                            necessidades específicas de cada cliente.
                        </p>
                        <img src={magnoPicture} alt="Família fundadora da Magno" />
                        <p>
                            Aqui na Magno Especializada, também oferecemos serviços de engenharia personalizados, desenvolvendo 
                            projetos completos que integram todos os aspectos do sistema, desde a concepção até a implementação. 
                            Nossa abordagem centrada no cliente nos permite colaborar de perto com nossos parceiros, garantindo 
                            que cada projeto seja entregue dentro do prazo e do orçamento, sem comprometer a qualidade.
                        </p>
                        <p>
                            Em resumo, somos uma empresa comprometida em fornecer produtos e serviços de primeira linha para 
                            atender às demandas crescentes do mercado. Com nossa dedicação à excelência e nossa paixão pela 
                            inovação, estamos prontos para enfrentar os desafios do setor e continuar a ser líderes em nosso 
                            segmento.
                        </p>
                    </History>
                </BoxInstitucional>
            </MainInstitucionalPage>
            <Footer />
        </>
    )
}

const MainInstitucionalPage = styled.main`
    width: 100vw;
    min-height: 600px;
    background-color: #ffffff;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`
const BoxTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 20px;
    gap: 6px;
`
const Line = styled.div`
    width: 140px;
    height: 1px;
    background-color: #494747;
    margin-bottom: 20px;
`
const Title = styled.h4`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 60px;
    color: #383636;

    @media (max-width: 670px) {
        font-size: 28px;
    }
    @media (max-width: 440px) {
        font-size: 22px;
    }
`
const BoxInstitucional = styled.div`
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F6F6;
`
const BoxTitleHistory = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
`
const FirstTitle = styled.span`
    width: 100%;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 60px;

    display: block;
    align-items: center;
    text-align: center;

    text-align: center;
    color: #970000;

    @media (max-width: 670px) {
        font-size: 28px;
    }
    @media (max-width: 440px) {
        font-size: 22px;
    }
`
const SecondTitle = styled.span`
    width: 100%;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 100;
    font-size: 62px;
    line-height: 70px;

    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5em;
    margin-top: 5px;

    color: #970000;

    @media (max-width: 670px) {
        font-size: 42px;
    }
    @media (max-width: 440px) {
        font-size: 32px;
        margin-top: 0px;
    }
`
const History = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
    box-sizing: border-box;
    padding: 50px 100px;
    gap: 15px;
 
    p{
    display: block;
    justify-content: center;
    align-items: center;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    text-align: justify;
    /* text-indent: 30px; */  // Dá uma entradinha em cada parágrafo 

    color: #000000;
    }

    img{
        width: 70%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media (max-width: 1000px) {
        padding: 40px 80px;
    }
    @media (max-width: 780px) {
        padding: 40px 50px;
    }
    @media (max-width: 670px) {
        padding: 40px 28px;
        font-size: 20px;
    }
    @media (max-width: 440px) {
        padding: 30px 15px;
        font-size: 18px;
    }
`