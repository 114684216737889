import styled from "styled-components"
import { BsWind } from "react-icons/bs";
import { BsCheckSquare } from "react-icons/bs";
import { MdWaterDrop } from "react-icons/md";
import { BsSnow2 } from "react-icons/bs";
import ar from "../../assets/images/image1.png";
import ventilacao from "../../assets/images/gas.jpg";
import water from "../../assets/images/waterpg.jpg";
import hvac from "../../assets/images/image 5.png";
import { useNavigate } from "react-router-dom";

export default function ServicesAndSolutions(){
    const navigate = useNavigate();

    function goPage(path){
        navigate(path);
    }

    return(
        <Container>
            <Line />
            <Details>
                <Title>Serviços e Soluções</Title>
                <Phrase>
                    Somos certificados por uma das maiores marcas de tecnologias no mercado. 
                    Nossos especialistas estão prontos para lhe apresentar as mais modernas soluções 
                    em climatização e sistemas de aquecimento.
                </Phrase>
            </Details>
            <ContainerProducts>
                <BoxProduct>
                    <BoxProduct onClick={() => goPage("/servicos/ar-condicionado")}>
                        <Product>
                            <BsSnow2 />
                            <TitleProduct>Ar Condicionado</TitleProduct>
                            <DescriptionProduct>
                                Mantenha-se refrescado e confortável com nossos sistemas eficientes em energia
                            </DescriptionProduct>
                        </Product>
                        <ProductImg>
                            <img src={ar} alt="" />
                        </ProductImg>
                    </BoxProduct>
                </BoxProduct>
                <BoxProduct>
                    <BoxProduct onClick={() => goPage("/servicos/ventilacao")}>
                        <ProductImg>
                            <img src={hvac} alt="" />
                        </ProductImg>
                        <Product>
                            <BsWind/>
                            <TitleProduct>Ventilação</TitleProduct>
                            <DescriptionProduct>
                                Renovação constante do ar para um ambiente refrigerado e saudável
                            </DescriptionProduct>
                        </Product>
                    </BoxProduct>
                </BoxProduct>
                <BoxProduct>
                    <BoxProduct onClick={() => goPage("/servicos/instalação-gas")}>
                        <Product>
                            <BsCheckSquare/>
                            <TitleProduct>Gás  encanado</TitleProduct>
                            <DescriptionProduct>
                                Solução eficiente e prática para suprir as necessidades energéticas de residências e empresas
                            </DescriptionProduct>
                        </Product>
                        <ProductImg>
                            <img src={ventilacao} alt="" />
                        </ProductImg>
                    </BoxProduct>
                </BoxProduct>
                <BoxProduct>
                    <BoxProduct onClick={() => goPage("/servicos/aquecimento-agua")}>
                        <ProductImg>
                            <img src={water} alt="" />
                        </ProductImg>
                        <Product>
                            <MdWaterDrop/>
                            <TitleProduct>Aquecimento de Água</TitleProduct>
                            <DescriptionProduct>
                                Água quente para seu conforto diário
                            </DescriptionProduct>
                        </Product>
                    </BoxProduct>
                </BoxProduct>
            </ContainerProducts>
        </Container>
    )
}

const Container = styled.section`
    width: 100%;
    min-height: 400px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0 0px;
`
const Line = styled.div`
    width: 140px;
    height: 2px;
    background-color: #EE343A;
    @media (max-width:450px) {
        width: 80px;
    }
`
const Details = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 25px 50px 10px;
    gap: 15px;
`
const Title = styled.div`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    @media (max-width:1090px) {
       font-size: 38px;
    }
    @media (max-width:800px) {
       font-size: 28px;
    }
    @media (max-width:550px) {
       font-size: 24px;
    }
    @media (max-width:450px) {
        line-height: 16px;
    }
`
const Phrase = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    margin-top: 5px;
    @media (max-width:1090px) {
       font-size: 22px;
    }
    @media (max-width:800px) {
       font-size: 18px;
    }
    @media (max-width:550px) {
        line-height: 25px;
        font-size: 16px;
    }
    @media (max-width:450px) {
        line-height: 15px;
       font-size: 12px;
    }
`
const ContainerProducts = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
`
const BoxProduct = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Product = styled.div`
    width: 50%;
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    cursor: pointer;
    svg{
        font-size: 55px;
        color: #365E75;
    }
    @media (max-width:800px) {
       height: 400px;
       gap:15px;
       svg{
            font-size: 40px;
        }
    }
    @media (max-width:700px) {
       height: 300px;
    }
    @media (max-width:550px) {
       height: 200px;
       gap:0px;
       svg{
            font-size: 20px;
        }
    }
    @media (max-width:450px) {
        height: 160px;
        svg{
            font-size: 12px;
        }
    }
`
const TitleProduct = styled.div`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    @media (max-width:1090px) {
       font-size: 40px;
    }
    @media (max-width:800px) {
       font-size: 34px;
        line-height: 40px;
    }
    @media (max-width:700px) {
       font-size: 28px;
    }
    @media (max-width:550px) {
        font-size: 20px;
        margin-top: 0px;
        line-height: 20px;
        margin-top: 5px;
        margin-bottom: 8px;
    }
    @media (max-width:450px) {
        font-size: 16px;
    }
`
const DescriptionProduct = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    box-sizing: border-box;
    padding: 10px 25px 10px;
    @media (max-width:1090px) {
        font-size: 24px;
    }
    @media (max-width:800px) {
        font-size: 18px;
    }
    @media (max-width:700px) {
        font-size: 16px;
        padding: 5px 15px 5px;
        line-height: 25px;
    }
    @media (max-width:550px) {
        font-size: 14px;
        padding: 0px 5px 0px;
        line-height: 20px;
    }
    @media (max-width:450px) {
         font-size: 12px;
        line-height: 15px;
    }
`
const ProductImg = styled.div`
    width: 50%;
    height: 570px;
    cursor: pointer;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:hover{
        opacity: 0.5;
        }
    }
    @media (max-width:800px) {
       height: 400px;
    }
    @media (max-width:700px) {
       height: 300px;
    }
    @media (max-width:550px) {
       height: 200px;
    }
    @media (max-width:450px) {
        height: 160px;
    }
`