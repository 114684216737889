import styled from "styled-components";

import { BsFillGeoAltFill } from "react-icons/bs";
import { BiLogoInstagram } from "react-icons/bi";
import { BiEnvelope } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <FooterContainer>
            <FooterTop>
                <InnerDiv>
                    <Title>Magno Especializada</Title>
                    <MagnoServico>
                        <DivLinks><p><Link to="/institucional">Site Institucional</Link></p></DivLinks>
                        <DivLinks><p><Link to="/contato">Fale conosco</Link></p></DivLinks>
                        <DivLinks><p><Link to="/">Serviços</Link></p></DivLinks>
                    </MagnoServico>
                </InnerDiv>
                <InnerDiv>
                    <Title>Unidades</Title>
                    <MagnoServico>
                        <DivLinks nonclickable={true}>
                            <Localization size="16">
                                <BsFillGeoAltFill />
                                <p>João Pessoa - PB</p>
                            </Localization>
                            <br />
                            <p>Av. Min. José Américo de Almeida, 42, Torre</p>
                            <br />
                        </DivLinks>
                        <DivLinks nonclickable={true}>
                            <Localization size="16">
                                <BsFillGeoAltFill />
                                <p>Cabedelo - PB</p>
                            </Localization>
                            <br />
                            <p>Rua Quatrocentos e Setenta e Quatro, 09, Lot. Progresso</p>
                        </DivLinks>
                    </MagnoServico>
                </InnerDiv>
                <InnerDiv>
                    <Title>Social</Title>
                    <MagnoServico>
                        <DivLinks>
                            <Localization size="22">
                                <BiLogoInstagram />
                                <p><a href="https://www.instagram.com/magno_especializada/" target="_blank">@magno_especializada</a></p>
                            </Localization>
                        </DivLinks>
                        <DivLinks nonclickable={true}>
                            <Localization size="22">
                                <BiEnvelope />
                                <p>sac@magnodistribuidora.com.br</p>
                            </Localization>
                        </DivLinks>
                        <DivLinks nonclickable={true}>
                            <Localization size="22">
                                <FiClock />
                                <p>
                                    Atendimento: 
                                    <br/> Seg. a Sex. de 08:00h às 18:00h
                                </p>
                            </Localization>
                        </DivLinks>
                    </MagnoServico>
                </InnerDiv>
                <InnerDiv none={true}>
                    <Title none={true}>Envie-nos sua opinião</Title>
                    <BoxForm>
                        <Form method="post" action="/form">
                            <InputBox>
                                <input type="text" name="name" id="idName" autocomplete="off" required />
                                <label for="idName">Seu nome</label>
                            </InputBox>
                            <InputBox>
                                <input type="email" name="email" id="idEmail" autocomplete="off" required />
                                <label for="idEmail">Seu E-mail</label>
                            </InputBox>
                            <InputBox>
                                <input type="text" name="opinion" id="idOpinion" autocomplete="off" required />
                                <label for="idOpinion">Sua opinião</label>
                            </InputBox>
                            <ButtonForm>Enviar</ButtonForm>
                        </Form>
                    </BoxForm>
                </InnerDiv>
            </FooterTop>
            <FooterBottom>
                <p>© Copyright 2010 - Magno Distribuidora - Todos os direitos reservados Magno Distribuidora 
                    com razão social Magno e Filhos LTDA.</p>
                <p>CNPJ 12.224.867/0001-00 / Avenida Jose Ferreira de Moraes, 235, 
                    Quadra04 Lote 29e Parque Esperanca, Renascer
                    Cabedelo - PB - CEP 58108-186</p>
            </FooterBottom>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    width: 100vw;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
`
const FooterTop = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    box-sizing: border-box;
    padding: 5px 15px;
    gap: 10px;
`
const InnerDiv = styled.div`
    min-width: 200px;
    min-height: 200px;
    box-sizing: border-box;
    padding: 5px 10px;
    margin-right: 25px;
    @media (max-width: 615px) {
        width: 100%;
        margin-right: 0px;
    }
    @media (max-width:450px) {
        display: ${(props) => props.none ? "none" : ""};
    }
`
const Title = styled.h4`
    margin-bottom: 5px;
    color: #423c3c;
    font-weight: 700;
    font-size: 18px;
`
const MagnoServico = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
  
`
const DivLinks = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #6b6969;
    font-size: 16px;
    margin-top: 5px;
    p{
        cursor: ${(props) => props.nonclickable ? "text": "pointer"};
        a{
            color: #6b6969;
            font-size: 16px;
            margin-top: 5px;
            text-decoration: none;
        }
    }
`
const Localization = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    svg{
        margin-right: 10px;
        font-size: ${(props) => props.size + "px"};
    }
`
const BoxForm = styled.div`
    width:250px;
     @media (max-width:301px) {
        display: none;
    }
`
const Form = styled.form`

`
const InputBox = styled.div`
    position: relative;
    margin: 20px 0;
    text-align: center;
    input{
        width: 100%;
        border: 0;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-size: 15px;
        outline: none;

        &:focus ~ label,
        &:valid ~label{
            transform:  translateY(-21px);
            font-size: 12px;
            color: #A0252E
        }
    }

    label{
        position: absolute;
        left: 0;
        bottom: 5px;
        transition:.5s;
        cursor: text;
        color: #6b6969;
    }
`
const ButtonForm = styled.button`
    cursor: pointer;
    height: 25px;
    background-color: #A0252E;
    border: 1px solid #A0252E;
    color: #ffffff;
    font-weight: 600;
    &:hover{
        background-color: #ffffff;
        color: #A0252E; 
    }
`
const FooterBottom = styled.div`
    width: 100%;
    min-height: 50px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 15px 20px;
    border-top: 1px solid #ccc;
    color: #6b6969;
    font-size: 14px;
    font-weight: lighter;
    gap: 6px;
`