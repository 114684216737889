import styled from "styled-components"

import svg1 from "../../assets/images/dec.png";
import svg from "../../assets/images/dec2.png";
import medical from "../../assets/images/ecomedical.jpg";
import bara from "../../assets/images/BARA.jpg";
import casaTudo from "../../assets/images/casatudo.jpg";
import setai from "../../assets/images/SETAI.jpg";

import { useNavigate } from "react-router-dom";

export default function ServicesPerformed(){
    const navigate = useNavigate();
    return(
        <Container>
            <Line />
            <BoxTitle>
                <img src={svg1} alt="detalhe decorativo em pomtinhos" />
                <Title>Alguns de Nossos Projetos Concluídos</Title>
            </BoxTitle>
            <BoxServices>
                <figure>
                    <img src={medical} alt="eco medical sul joão pessoa" />
                    <figcaption>Eco Medical Sul</figcaption>
                </figure>

                <figure>
                    <img src={bara} alt="BA'RA hotel João Pessoa" />
                    <figcaption>BA'RA hotel</figcaption>
                </figure>

                <figure>
                    <img src={casaTudo} alt="Casa Tudo Campina Grande" />
                    <figcaption>Casa Tudo Campina Grande </figcaption>
                </figure>

                <figure>
                    <img src={setai} alt="Setai yacht setai hotel João Pessoa" />
                    <figcaption>Setai Yacht - Setai</figcaption>
                </figure>
            </BoxServices>
            <BoxButton>
                <DivButton>
                    <Button onClick={()=> navigate("/contato")}>Peça seu orçamento</Button>
                </DivButton>
                <img src={svg} alt="detalhe decorativo em pomtinhos" />
            </BoxButton>
        </Container>
    )
}

const Container = styled.section`
    width: 100vw;
    min-height: 500px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 35px 50px 10px;
    gap: 15px;
    @media (max-width:600px) {
        padding: 15px 10px 10px;
    }
`
const Line = styled.div`
    width: 140px;
    height: 2px;
    background: #EE343A;
    margin-top: 30px;
    @media (max-width:450px) {
        width: 80px;
    }
`
const BoxTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
    }
    @media (max-width:800px) {
       img{
            width: 30px;
        }
    }
    @media (max-width:690px) {
        img{
            display: none;
        }
    }
`
const Title = styled.div`
    width: 100%;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    @media (max-width:1090px) {
       font-size: 38px;
       line-height: 50px;
    }
    @media (max-width:800px) {
       font-size: 28px;
       line-height: 40px;
    }
    @media (max-width:550px) {
       font-size: 24px;
       line-height: 30px;
    }
    @media (max-width:450px) {
        line-height: 16px;
        line-height: 30px;
    }
`
const BoxServices = styled.div`
    margin-top: 20px;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    gap: 70px;
    box-sizing: border-box;
    padding: 20px 35px 20px;
    figure{
        width: 530px;
        height: 408px;
        margin-bottom: 30px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.25);
        img{
            width: 100%;
            height: 90%;
            object-fit: cover;
            border-radius: 30px 30px 0px 0px;
        }
        figcaption{
            width: 100%;
            height: 15%;
            background: #000000;
            border-radius: 0px 0px 30px 30px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #ccc;
        }
    }
    @media (max-width:600px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content:flex-start; 
        align-items: center;
        gap: 25px;
        box-sizing: border-box;
        padding: 20px 5px;

        overflow: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 5px;
            height: 6px;
            background-color: #aaa;
        }
        &::-webkit-scrollbar-thumb {
            background: #000;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-track {
            background-color: #ccc;
        }

        figure{
             width: 450px;

             img{
                 width: 450px;
             }
        }
    }
    @media (max-width:450px) {
        figure{
            width: 300px;
            height: 238px;

            img{
                width: 250px;
            }
            figcaption{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
`
const BoxButton = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 35px 20px;
    img{
        width: 40px;
    }
    @media (max-width:700px) {
        height: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 15px 10px;
        img{
            display: none;
        }
    }
`
const DivButton = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Button = styled.button`
    background: linear-gradient(180deg, #FB7D6C 0%, #560404 100%);
    box-shadow: 5px 8px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    margin-left: 55px;

    box-sizing: border-box;
    padding: 10px 15px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    @media (max-width:450px) {
        font-size: 14px;
    }
`