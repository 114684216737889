import { useState } from "react"
import styled from "styled-components"

import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

import { Hourglass } from 'react-loader-spinner'
import { IoMdCheckboxOutline } from "react-icons/io";
import CarouselServices from "./CarouselServices";

export default function ServicesComponent({ sistemsArray, title }) {
    const [productSelected, setProductSelected] = useState();
    const [open, setOpen] = useState(false);
    const [mapProduct, setMapProduct] = useState([]);

    function openSelect() {
        setOpen(!open);
    }

    function productChoice(product) {
        setProductSelected(product.name);
        const array = [];
        array.push(product)
        setMapProduct(array);
    }

    return (
        <BoxOptions>
            <Line />
            <Title>{title}</Title>
            <SelectProduct onClick={openSelect}>
                <p>{productSelected ? productSelected : "Selecione"}</p>
                {open ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                <Options open={open}>
                    {sistemsArray.length > 0 ?
                        sistemsArray.map((product) => (
                            <Product key={product.id} onClick={() => productChoice(product)}>{product.name}</Product>
                        )) : ""}
                </Options>
            </SelectProduct>
            {mapProduct.length > 0 ?
                mapProduct.map((p) => (
                    <ContainerProductContent key={p.id}>
                        <TitleProduct>{p.name}</TitleProduct>
                        <BoxProductContent>
                            <ProductPicture>
                                <CarouselServices arrayProducts={p.image}/>
                            </ProductPicture>
                            <ProductContent>
                                <p>{p.text}</p>
                                {p.points.map((point) => (<span key={p.id}>&#8226; &nbsp;&nbsp;{point}</span>))}
                                <ContainerBox>
                                    {p.icones.map((i) => (<Box><IoMdCheckboxOutline /><p>{i}</p></Box>))}
                                </ContainerBox>
                            </ProductContent>
                        </BoxProductContent>
                    </ContainerProductContent>
                ))
                :
                < BoxWait>
                    <WaitText>Selecione uma opção</WaitText>
                    <Hourglass
                        visible={true}
                        height="100"
                        width="100"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#A0252E', '#b95c62']}
                    />
                </BoxWait>
            }
        </BoxOptions>
    )
}

const BoxOptions = styled.div`
    width: 100%;
    min-height: 600px;
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const Line = styled.div`
    width: 140px;
    height: 1px;
    background-color: #494747;
    margin-top: 60px;
`
const Title = styled.h4`
    color: #383636;;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    margin-top: 40px;
    text-align: center;
`
const SelectProduct = styled.div`
    width: 300px;
    height: 50px;
    background-color: #ccc;
    border-bottom: 1px solid #918f8f;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    margin-top: 45px;
    cursor: pointer;
    p{
        font-family: 'Public Sans';
        font-style: normal;
        font-size: 20px;
        line-height: 60px;
        color: #918f8f;
        display: block;
        margin-left: 10px;
    }
    svg{
        position: absolute;
        top: 16px;
        left: 270px;
        font-size: 24px;
    }
`
const Options = styled.div`
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    min-height: 50px;
    background-color: #F6F6F6;
    display: ${(props) => props.open ? "flex" : "none"};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 3;
`
const Product = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ccc;
    border-bottom: 1px solid #918f8f;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 15px 0px 0px 10px;
    font-family: 'Public Sans';
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #383636;
    display: block;
    &:hover{
        border-bottom: 1px solid #707070;
        background-color: #9b9999;
   }
   &::after{
        border-bottom: 1px solid #707070;
        background-color: #9b9999;
   }
`
const ContainerProductContent = styled.div`
    width: 100%;
    min-height: 400px;
    background-color: #F6F6F6;
    box-sizing: border-box;
    padding: 10px 15px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const TitleProduct = styled.h4`
    color: #383636;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    margin-top: 40px;
    margin-bottom:50px;
`
const BoxProductContent = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
const ProductPicture = styled.div`
    width: 50%;
    height: 100%;
    @media (max-width: 1000px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const ProductContent = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px;
    p{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #000000;
        text-align: justify;
        margin-bottom: 15px;
    }
    span{
        display: block;
        width: 100%;
        height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #000000;
        text-align: justify;
    }
    @media (max-width: 1000px) {
        width: 100%;
    }
`
const ContainerBox = styled.div`
    width: 480px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-top: 20px;
    @media (max-width: 1000px) {
        width:320px;
    }
`
const Box = styled.div`
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 104px;
    border: 1px solid #9E9E9E;
    border-radius: 15px;
    background-color: #ccc;
    p{
        text-align: center;
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;
        letter-spacing: 0.1em;
        color: #383636;
        display: block;
        margin-top: 15px;
    }
    svg{
        font-size: 28px;
        color: #383636;;
    }
    @media (max-width: 1000px) {
        width:150px;
        height: 154px;
         p{
        font-size: 16px;
        line-height: 15px;
    }
    svg{
        font-size: 30px;
        color: #383636;;
    }
    }
`
const BoxWait = styled.div`
    width: 100%;
    height: 300px;
    background-color:  #F6F6F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const WaitText = styled.h3`
    margin-bottom: 25px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 60px;
    color: #A0252E;
`