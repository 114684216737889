import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import Contact from "../../components/contactServicesPage/Contact";

import air from "../../assets/images/AIR.png";
import { useEffect } from "react";
import ServicesComponent from "../../components/servicesComponent/ServicesComponent";

import dvm1 from "../../assets/images/DVM1.jpeg";
import dvm2 from "../../assets/images/DVM2.jpg";
import dvm3 from "../../assets/images/DVM3.jpg";

import multi1 from "../../assets/images/MULTI1.jpeg";
import multi2 from "../../assets/images/MULTI2.png";
import multi3 from "../../assets/images/MULTI3.jpeg";

import comum1 from "../../assets/images/COMUM1.jpeg";
import comum2 from "../../assets/images/COMUM2.jpeg";
import comum3 from "../../assets/images/COMUM3.png";

import chi1 from "../../assets/images/CHI1.jpeg";
import chi2 from "../../assets/images/CHI2.jpeg";
import chi3 from "../../assets/images/CHI3.jpeg";

export default function AirPage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const title = "Sistemas";

    const sistemsArray = [
        {
            id:1,
            name: "DVM(VRF)",
            text: "Os sistemas Samsung DVM S de ar-condicionado possuem alta eficiência energética, são fáceis de instalar e, como são compactos, garantem maior aproveitamento do espaço de instalação.",
            points: ["Alta eficiência", "Instalação flexível", "Operação confiável e confortável"],
            icones: ["Fácil instalação", "EFICIÊNCIA ENERGÉTICA", "Economia De espaço", "Mais Leve"],
            image: [dvm1, dvm2, dvm3]
        },
        {
            id: 2,
            name: "FJM (MULTI SPLIT)",
            text: "Os sistemas de ar-condicionado FJM (Multi Split) oferecem uma solução eficiente e versátil para climatização de ambientes múltiplos. Com tecnologia avançada e design inovador, esses sistemas garantem conforto térmico e praticidade na instalação",
            points: ["Tecnologia avançada", "Design inovador", "Conforto térmico garantido"],
            icones: ["Eficiência Versátil", "Instalação Prática", "Desempenho Inovador", "Controle Personalizado"],
            image: [multi1, multi2, multi3]
        },
        {
            id: 3,
            name: "SINGLE SPLIT (COMUM)",
            text: "O sistema Single Split (Comum) de ar-condicionado oferece desempenho confiável para ambientes individuais. Com fácil instalação e operação intuitiva, é a escolha ideal para quem busca praticidade e eficiência.",
            points: ["Desempenho confiável", "Fácil instalação", "Operação intuitiva"],
            icones: ["Confiabilidade Individual", "Instalação Descomplicada", "Operação Intuitiva", "Eficiência Prática"],
            image: [comum1, comum2, comum3]
        },
        {
            id: 4,
            name: "ÁGUA GELADA (CHILLER)",
            text: "Os sistemas de ar-condicionado com Água Gelada (Chiller) são projetados para aplicações que demandam grande capacidade de refrigeração. Com eficiência energética e controle preciso, esses sistemas garantem climatização ideal para espaços amplos.",
            points: ["Alta capacidade de refrigeração", "Eficiência energética", "Controle preciso"],
            icones: ["Refrigeração Potente", "Eficiência Energética", "Controle Preciso", "Desempenho Amplo"],
            image: [chi1, chi2, chi3]
        },
    ];
    
    return(
        <>
            <Navbar />
            <MainAir>
                <Banner>
                    <img src={air} alt="banner com ar condicionado" />
                </Banner>
                <ServicesComponent sistemsArray={sistemsArray} title={title}/>
                <Contact/>
            </MainAir>
            <Footer />
        </>
    )
}

const MainAir = styled.div`
    width: 100vw;
    min-height: 700px;
    margin-top: 80px;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`