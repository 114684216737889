import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styled from "styled-components";
import Contact from "../../components/contactServicesPage/Contact";
import ventilation from "../../assets/images/VENTILATION1.png";
import { useEffect } from "react";
import ServicesComponent from "../../components/servicesComponent/ServicesComponent";

import coifa from "../../assets/images/coifa.jpeg";
import coifa80 from "../../assets/images/coifa80.jpeg";

import simple1 from "../../assets/images/simples.jpeg";
import simple2 from "../../assets/images/simples2.jpeg";

import erv1 from "../../assets/images/erv1.jpeg";
import erv2 from "../../assets/images/erv2.jpeg";
import erv80 from "../../assets/images/erv80.jpeg";

export default function VentilationPage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const title = "Ventilação";

    const sistemsArray = [
        {
            id: 1,
            name: "RENOVAÇÃO DE AR",
            text: "A renovação de ar simples é uma solução eficaz para manter ambientes frescos e saudáveis. Ao utilizar sistemas de ventilação, essa abordagem garante a circulação constante do ar, contribuindo para a qualidade do ambiente interno.",
            points: ["Renovação eficaz", "Melhoria da qualidade do ar", "Operação simplificada"],
            icones: ["Circulação Constante", "Ambiente Saudável", "Operação Descomplicada", "Bem-Estar Garantido"],
            image: [simple1, simple2]
        },
        {
            id: 2,
            name: "RENOVAÇÃO DE AR ERV",
            text: "A unidade interna ERV (ventilação com recuperação de calor) oferece resfriamento e aquecimento excepcionais durante todo o ano com um design de ventilação bidirecional. Possui entradas e saídas de ar em ambos os lados da unidade que fornecem eficiência de ventilação superior. E sua área de troca de calor transfere energia térmica enquanto evita que os contaminantes descarregados voltem a entrar em ambientes internos, recuperando até 70% da energia* necessária para resfriar ou aquecer os ambientes. Portanto, sua recuperação eficiente de calor mantém a temperatura interna durante o inverno e evita que o calor externo e a umidade entrem no interior durante o verão.",
            points: ["Eficiência energética", "Recuperação de calor", "Sustentabilidade ambiental"],
            icones: ["Renovação Econômica", "Recuperação Sustentável", "Eficiência Energética", "Ambiente Confortável"],
            image: [erv80, erv1, erv2]
        },
        {
            id: 3,
            name: "COIFA PARA COZINHA",
            text: "As coifas desempenham um papel fundamental na manutenção de cozinhas limpas e livres de odores indesejados. Com design moderno e funcionalidades avançadas, as coifas proporcionam um ambiente de cozinha mais agradável e seguro.",
            points: ["Design moderno", "Filtragem eficaz", "Controle de odores"],
            icones: ["Estilo na Cozinha", "Ambiente Limpo", "Controle de Odores", "Funcionalidade Avançada"],
            image: [coifa80, coifa]
        },
    ];


    return(
        <>
            <Navbar />
            <MainVentilation>
                < Banner>
                    <img src={ventilation} alt="banner com sistema de ventilação" />
                </Banner>
                <ServicesComponent sistemsArray={sistemsArray} title={title} />
                <Contact />
            </MainVentilation>
            <Footer />
        </>
    )
}

const MainVentilation = styled.div`
    width: 100vw;
    min-height: 700px;
    margin-top: 80px;
`
const Banner = styled.div`
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 670px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
    @media (max-width: 370px) {
        height: 200px;
    }
`
