import styled, { keyframes } from "styled-components"
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Sidebar({ setSidebar, sidebar }){
  const [ servicesMenu, setServicesMenu ] = useState(false);

    function closedSidebar(){
        setSidebar(false);
    }

  function openServicesMenu(){
    setServicesMenu(!servicesMenu);
  }

    return(
        <ContainerSidebar active={sidebar}>
            <IoIosCloseCircleOutline onClick={closedSidebar}/>
        <BoxOptions>
          <ul>
            <li><Link to="/">Início</Link></li>
            <li><Link to="/institucional">Institucional</Link></li>
            <li><Link to="/contato">Contato</Link></li>
            <li >
              <ButtonServices onClick={openServicesMenu}>
                Serviços
                </ButtonServices>
              <ContainerServices open={servicesMenu}>
                <BoxList>
                  <ul>
                    <li><Link to="/servicos/instalação-gas">Gás encanado</Link></li>
                    <li><Link to="/servicos/ventilacao">Ventilação</Link></li>
                    <li><Link to="/servicos/ar-condicionado">Ar condicionado</Link></li>
                    <li><Link to="/servicos/aquecimento-agua">Aquecimento de água</Link></li>
                  </ul>
                </BoxList>
              </ContainerServices>
            </li>
          </ul>
        </BoxOptions>
        </ContainerSidebar>
    )
}

const ContainerSidebar = styled.div`
    width: 300px;
    height: 100vh;
    background-color: #f5f5f5;;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 15px 15px;

    position: fixed;
    top:0;
    right: ${(props)=> props.active? "0":"-100%"};
    animation: showSidebar .5s;

    > svg{
        font-size:40px;
        color: #383636;
    }

     @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 300px;
    }
  }
`
const BoxOptions = styled.div`
  width: 100%;
  height: 95%;
  margin-top: 20px;

   ul{
        list-style: none;
        float: none;
        padding: 10px 10px;
        
        li{
            position: relative;
            float: none;
            text-align: center;
            margin-bottom: 20px;
            width: 100%;
            height: 45px;
            background-color: #ccc;
            border-radius: 10px;
            font-size: 16px;

            a{
              font-size: 20px;
              text-decoration: none;
              color: #383636;
              display: block;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding: 12px 10px;
            }

            &:hover{
              background-color: #383636;
          
              > a{
                color: #ffffff;
                font-weight: 700;
              }

              button{
                color: #ffffff;
                font-weight: 700;
              }
            }
        }
    }
`
const ButtonServices = styled.button`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 10px;
    text-align: center;
    border: none;
    background-color: transparent;
    color: #383636;;
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
`
const ContainerServices = styled.div`
    width: 100%;
    min-height: 200px;
    background-color: #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 2px #666161;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    transition: all 0.5s;
    position: absolute;
    right: ${(props) => props.open ? "0" : "-200%"};
    animation: showServices .8s;

     @keyframes showServices {
        from {
            opacity: 0;
            width: 0;
        }
        to {
            opacity: 1;
            width: 100%;
        }
    }
`
const BoxList = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-top: 10px;
     ul{
        list-style: none;
        float: none;
        padding: 10px 10px;

        li{
            float: none;
            text-align: center;
            margin-bottom: 20px;
            width: 100%;
            min-height: 40px;
            background-color: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 10px;
            box-sizing: border-box;
            color: #000000;

            a{
              text-decoration: none;
              color: #000000;
              font-size: 17px;
              text-align: center;
              display: block;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
            }

            &:hover{
                border: 1px solid #ccc;
                background-color: #ffffff;
          
              a{
                color: red;
              }
            }
        }
    }
`