import styled from "styled-components"
import { BsFillCartFill } from "react-icons/bs";
import logoMarca from "../assets/images/logo2.png";
import NavBarMobile from "./NavBarMobile";
import { useEffect, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";

export default function Navbar() {
    const arrayServices = ["Gás encanado", "Ventilação", "Ar condicionado", "Aquecimento de água"]

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [navBar, setNavBar] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [isActive, setIsActive] = useState(null);
    const navigate = useNavigate();

    function detectSize() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize);

        return () => {
            window.removeEventListener('resize', detectSize);
        }
    }, [detectSize]);

    function detectScroll() {
        if (window.scrollY >= 80) {
            setNavBar(true);
        } else {
            setNavBar(false);
        }
    }

    function openSelect() {
        setOpenMenu(!openMenu);
    }

    function openServicePage(service) {
        switch (service.toLowerCase()) {
            case 'Gás encanado'.toLowerCase():
                navigate("/servicos/instalação-gas");
                break;
            case 'Ventilação'.toLowerCase():
                navigate("/servicos/ventilacao");
                break;
            case 'Ar condicionado'.toLowerCase():
                navigate("/servicos/ar-condicionado");
                break;
            case 'Aquecimento de água'.toLowerCase():
                navigate("/servicos/aquecimento-agua");
                break;
            default:
                console.log("Esta página ainda está em construção")
                break;
        }

    }

    function handleClick(optionId) {
        setIsActive(optionId);
    }

    window.addEventListener('scroll', detectScroll);

    return (
        <Header>
            <MagnoDistribuidora navBar={navBar}>
                <BsFillCartFill /><LinkMagnoDistribuidora><a href="https://www.magnodistribuidora.com.br/ar-condicionado" target="_blank">Magno Distribuidora</a></LinkMagnoDistribuidora>
            </MagnoDistribuidora>
            <Nav navBar={navBar}>
                <Link to="/"><Logo src={logoMarca} alt="Logo Magno Serviços" /></Link>
                {windowWidth >= 940 ?
                    <ContainerMenu>
                        <Link to="/">
                            <OptionsMenu 
                                isActive={isActive === "1"? "red":"#000000"} 
                                onClick={() => handleClick("1")}>
                                    Início
                            </OptionsMenu>
                        </Link>
                        <LineMenu navBar={navBar}></LineMenu>
                        <OptionsMenu onClick={openSelect} open={openMenu}>
                            Serviços
                            <ContainerOptionsServices open={openMenu}>
                                {arrayServices.length > 0 ?
                                    arrayServices.map((service, index) => (
                                        <OptionService key={index} onClick={() => openServicePage(service)}>
                                            {service}
                                        </OptionService>
                                    ))
                                    :
                                    ""}
                            </ContainerOptionsServices>
                        </OptionsMenu>
                        <LineMenu navBar={navBar}></LineMenu>
                        <Link to="/institucional">
                            <OptionsMenu 
                                isActive={isActive === "2" ? "red" :"#000000"} 
                                onClick={() => handleClick("2")}>
                                    Institucional
                            </OptionsMenu>
                        </Link>
                        <LineMenu navBar={navBar}></LineMenu>
                        <Link to="/contato">
                            <OptionsMenu 
                                isActive={isActive === "3" ? "red" :"#000000"} 
                                onClick={() => handleClick("3")}>
                                    Contato
                            </OptionsMenu>
                        </Link>
                    </ContainerMenu>
                    :
                    <NavBarMobile navBar={navBar} />
                }
            </Nav>
        </Header>
    )
}

const Header = styled.header`
    width: 100vw;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #ffffff;
    box-shadow: rgb(0, 0, 0) -5px 1px 15px -8px;
`
const MagnoDistribuidora = styled.div`
    width: 100%;
    height: 20px;
    background-color: #000000;
    display: ${(props) => props.navBar === false ? "flex" : "none"} ;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    box-sizing: border-box;
    padding: 0 40px;
    font-size: 14px;
    svg{
        color: #ffffff;
    }
`
const LinkMagnoDistribuidora = styled.div`
    color: #ffffff;
    cursor: pointer;
    a{
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #ffffff;
        text-decoration: none;
    }
`
const Nav = styled.nav`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: ${(props) => props.navBar === false ? "#ffffff" : "#000000"};
    a{
        text-decoration: none;
        color: #000000;
    }
`
const Logo = styled.img`
    width: 90px;
    height: 60px;
`
const ContainerMenu = styled.div`
    width: 70%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding-right: 25px;
    border-radius: 30px;
    background-color: #ededed;
    font-weight: 600;
    @media (max-width:1228px) {
        width: 85%;
    }
`
const LineMenu = styled.div`
    height: 30px;
    width: 1px;
    background-color: ${(props) => props.navBar === false ? "#ffffff" : "#000000"};
`
const OptionsMenu = styled.div`
    text-align: center;
    margin: 8px 0px 8px 8px;
    height: 30px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    position: relative;
    background-color: ${(props) => props.open ? "#ccc" : "#ededed"};
    border-radius: 5px 5px 0px 0px ;
    color: ${(props) => props.isActive };
    &:hover{
        color: red;
        font-weight: bold;
    }
`
const ContainerOptionsServices = styled.div`
    position: absolute;
    left: 0;
    top: 30px;
    width: 600px;
    height:auto;
    box-sizing: border-box;
    padding-top: 15px;
    background-color: #ccc;
    display: ${(props) => props.open ? "flex" : "none"};
    justify-content:space-between;
    align-items: flex-start;
    z-index: 3;
    border-radius: 0px 5px 5px 5px ;
    @media (max-width:1228px) {
        width: 550px;
    }
`
const OptionService = styled.div`
    width: 150px;
    height:40px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #ccc;
    border-bottom: 1px solid #918f8f;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #383636;
    font-weight: 400;
    display: block;
    &:hover{
        color: red;
        font-weight: bolder;
        border-bottom: 1px solid #383636;
    }
     @media (max-width:1228px) {
        width: 140px;
        font-size: 13px;
    }
`