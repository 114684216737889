import styled from "styled-components"
import { CiMenuBurger } from "react-icons/ci";
import { useState } from "react";

import Sidebar from "./componentsNavBarMobile/Sidebar";

export default function NavBarMobile({navBar}){
    const [ sidebar, setSidebar ] = useState(false);

    function showSidbar(){
        setSidebar(!sidebar);
    }
    
    return(
        <MenuMobile nav={navBar}>
            <CiMenuBurger onClick={showSidbar}/>
            {sidebar && <Sidebar setSidebar={setSidebar} sidebar={sidebar}/>}
        </MenuMobile>
    )
}

const MenuMobile = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 15px;
    > svg{
        cursor: pointer;
        font-size: 35px;
        color: ${(props) => props.nav === false ? "#000000" : "#ffffff"};
    }
`