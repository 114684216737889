import styled from "styled-components"
import './carouselStyle.css';

import ar from "../../assets/images/SAMSUNG.png";
import arCondicionado from "../../assets/images/carrossel-1.png";
import capa from "../../assets/images/capa.png";
import aguaCapa from "../../assets/images/aguaCapa.png";
import newCapa from "../../assets/images/newCapa.jpg";

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

//css swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import { register } from 'swiper/element/bundle';
register();

export default function Carousel(){

    return(
        <Container>
            <Swiper
                modules={[Autoplay]} 
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                
            >
                <SwiperSlide><img alt="slide" src={capa} className="slide" /></SwiperSlide>
                <SwiperSlide><img alt="slide" src={newCapa} className="slide" /></SwiperSlide>
                <SwiperSlide><img alt="slide" src={ar} className="slide" /></SwiperSlide>
                <SwiperSlide><img alt="slide" src={aguaCapa} className="slide" /></SwiperSlide>
                <SwiperSlide><img alt="slide" src={arCondicionado} className="slide" /></SwiperSlide>
            </Swiper>
        </Container>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 500px;
    background-color: #ffffff;
    margin-top: 80px;
    @media (max-width: 770px) {
        height: 400px;
    }
    @media (max-width: 640px) {
        height: 300px;
    }
`