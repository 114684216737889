import styled from "styled-components"
import contact from "../../assets/images/CONTACT.jpg";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoLogoInstagram } from "react-icons/io";

export default function Contact(){
    function openInstagram(){
        let linkInst = "https://www.instagram.com/magno_especializada/"
        window.open(linkInst);
    }
    function openWpp(unit){
        
        if(unit === "JP"){
            let linkWpp = `https://wa.me/5583987985654`
            window.open(linkWpp);
        } else {
            let linkWpp = `https://wa.me/5583986098536`
            window.open(linkWpp);
        }
    }

    return(
        <ContainerContact>
            <Line />
            <Title>FALE COM UM VENDEDOR</Title>
            <BoxSeller>
                <Sellers>
                    < Unit>
                        <span>Unidade de João Pessoa - PB</span>
                        <p>Endereço:</p>
                        <p>Av. Min. José Américo de Almeida, 42, Torre.</p>
                        <p>Instagram:</p>
                        <InstButton onClick={openInstagram}>
                            <IoLogoInstagram /> Magno
                        </InstButton>
                        <p>WhatsApp:</p>
                        < WppButton onClick={() => openWpp("JP")}>
                            <IoLogoWhatsapp/> (83) 9 8798-5654
                        </WppButton>
                    </Unit>
                    < Unit>
                        <span>Unidade de Cabedelo - PB</span>
                        <p>Endereço</p>
                        <p>Rua Quatrocentos e Setenta e Quatro, 09, Lot. Progresso.</p>
                        <p>Instagram:</p>
                        <InstButton onClick={openInstagram}>
                            <IoLogoInstagram /> Magno
                        </InstButton>
                        <p>WhatsApp:</p>
                        < WppButton onClick={() => openWpp("CB")}>
                            <IoLogoWhatsapp/> (83) 9 86098536
                        </WppButton>
                    </Unit>
                </Sellers>
                <img src={contact} alt="mesinha com notebook em cima" />            
            </BoxSeller>
        </ContainerContact>
    )
}

const ContainerContact = styled.div`
    width: 100%;
    min-height: 300px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 0px;
`
const Line = styled.div`
    width: 140px;
    height: 2px;
    background-color: #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
`
const Title = styled.h4`
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    color: #383636;
    @media (max-width:700px) {
       font-size: 28px;
    }
    @media (max-width:500px) {
       font-size: 24px;
    }
    @media (max-width:450px) {
       font-size: 20px;
    }
`
const BoxSeller = styled.div`
    width: 100%;
    min-height: 700px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 80px;
    > img{
        width: 50%;
        height: 600px;
        object-fit: cover;
    }
    @media (max-width:780px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 40px;
        min-height: 500px;
        img{
            display: none;
        }
    }
    @media (max-width:700px) {
        min-height: 700px;
    }
`
const Sellers = styled.div`
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width:780px) {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.33);
    }
    @media (max-width:700px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
const Unit = styled.div`
    width: 100%;
    min-height: 200px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px 50px;
    margin-bottom: 30px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #000000;

    > span{
        display: block;
        margin-bottom: 10px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        align-items: center;
        color: #B40000;
    }

    > p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
    }
    @media (max-width:700px) {
        span{
            font-size: 20px;
        }
    }
    @media (max-width:450px) {
       span{
        font-size: 18px;
       }
       p{
        font-size: 16px;
       }
    }
`
const WppButton = styled.button`
    width: 180px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: #3EBA2A;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    svg{
        font-size: 18px;
        color: #ffffff;
    }
    &:hover{
        background-color: #ffffff;
        border: 1px solid #3EBA2A;
        color: #3EBA2A;
        svg{
            color: #3EBA2A;
        }
    }
    &:active{
        background-color: #134c0a;
        border: 1px solid #134c0a;
    }
    @media (max-width:450px) {
        width: 140px;
        font-size: 12px;
        svg{
            font-size: 14px;
        }
    }
`
const InstButton = styled.button`
    width: 180px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background: linear-gradient(to bottom right, #5B51D8, #C13584, #FCAF45);
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    svg{
        font-size: 18px;
        color: #ffffff;
    }
    &:hover{
        background: linear-gradient(#ffffff, #ffffff, #ffffff);
        border: 1px solid #E1306C;
        color: #E1306C;
        svg{
            color: #E1306C;
        }
    }
    &:active{
        background: linear-gradient(#FCAF45, #FCAF45, #FCAF45);
        border: 1px solid #FCAF45;
    }
    @media (max-width:450px) {
        width: 140px;
        font-size: 14px;
        svg{
         font-size: 14px;
        }
    }
`